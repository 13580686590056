import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { GETAPI, MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';

const Teachers = () => {
  const [Teacher, setTeacher] = useState([]);
  const getView = async () => {
    try {

      const result2 = await GETAPI('/list-teachers');
      if (result2) {
        setTeacher(result2);

      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getView();
  }, []);

  const baseUrl = 'https://api.websitesolution.cloud'
 // Dynamic Meta Tags By Disha Halkara 29-7-24
 const [metaDescription, setMetaDescription] = useState('');
 const [metaKeywords, setMetaKeywords] = useState('');
 const [metaTitle, setMetaTitle] = useState('');
 const [ogImage, setOgImage] = useState('');
 const [ogUrl, setOgUrl] = useState('');
 const getMeta = async () => {
   try {
       const result = await MetaPOSTAPI('/list-meta-details', 'teachers');
    
       // Assume result contains the required meta data
       setMetaDescription(result[0].meta_description || 'Default description');
       setMetaKeywords(result[0].meta_keyword || 'Default keywords');
       setMetaTitle(result[0].meta_title || 'Default title');
       setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
       setOgUrl(result[0].og_url || MetabaseUrl);
   } catch (error) {
       console.error("Error fetching data:", error);
   }
};
 // Update meta tags based on state
 useEffect(() => {
   window.scrollTo(0, 0);

   // Update document title
   document.title = metaTitle;

   // Remove existing meta tags if any
   const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
   existingMetaTags.forEach(tag => tag.remove());

   // Create and append meta tag for description
   const metaDescriptionTag = document.createElement('meta');
   metaDescriptionTag.name = 'description';
   metaDescriptionTag.content = metaDescription;
   document.head.appendChild(metaDescriptionTag);

   // Create and append meta tag for keywords
   const metaKeywordsTag = document.createElement('meta');
   metaKeywordsTag.name = 'keywords';
   metaKeywordsTag.content = metaKeywords;
   document.head.appendChild(metaKeywordsTag);

   // Create and append Open Graph meta tags
   const metaTags = [
       { property: 'og:title', content: metaTitle },
       { property: 'og:description', content: metaDescription },
       { property: 'og:type', content: 'website' },
       { property: 'og:url', content: ogUrl },
       { property: 'og:image', content: ogImage },
       { property: 'og:image:alt', content: metaTitle }
   ];

   metaTags.forEach(tag => {
       const metaTag = document.createElement('meta');
       metaTag.property = tag.property;
       metaTag.content = tag.content;
       document.head.appendChild(metaTag);
   });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

 
 useEffect(() => {

   getMeta();
 }, []);
  return (
    <>
      <Header />
      
            {/* <!-- Breadcamp SECTION --> */}
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        {/* <h6>Contact us</h6> */}
                        <h1>Teachers</h1>
                        <p>Reach out to Boson International School for inquiries, admissions, or to schedule a visit. We're here to assist you in every step of your educational journey.</p>
                    </div>
                </div>
            </section>
                        {/* <!-- DIRECTOR SECTION --> */}
                        <section className="wrapper" style={{ "background": "#00325a0a" }} >
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-5 marginright">
                            <figure className="imagesection--director"><img src="/assets/images/director.png" alt="" /></figure>
                        </div>
                        <div className="col-md-7 ">
                            <div className="main-headwrap">
                                <h2 className="heading">Director <span>institution dedicated </span></h2>
                                <p className="headtext">
                                    Under the visionary leadership of our Director, Boson International School in Ujjain is steadfastly committed to fostering an environment of academic excellence and holistic development. With a profound dedication to educational innovation and student success, our Director leads by example, ensuring that every aspect of our institution reflects a commitment to nurturing well-rounded individuals. By promoting a culture of continuous improvement and personalized learning experiences, our Director empowers both students and educators to strive for excellence in academics, character development, and community engagement. At Boson International School, our Director's unwavering dedication inspires a dynamic learning community where each student is encouraged to achieve their full potential and become future-ready global citizens.
                                </p>
                            </div>
                            <div className="principal--signa">
                                <h6>Director</h6>
                                <span>Dr. Gulrez Sheikh</span>
                                <b>Boson International School</b>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
         
      {/* <!-- Table --> */}

      <section class="wrapper" style={{ "background": "#00325a0a" }}>
        <div class="container">
          <div class="row">

            <div class="tavle--mainwrap">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sr.no </th>
                    <th scope="col">Teacher name</th>
                    <th scope="col">DESIGNATION</th>
                    <th scope="col">QUALIFICATION</th>
                    <th scope="col">TECHNICAL DEGREE</th>
                    <th scope="col">EXPERIENCE</th>
                    {/* <th scope="col">TEACHING EXPERIENCE IN YEAR</th> */}
                  </tr>
                </thead>
                <tbody>

                  {Teacher.map((row, index) => (
                    <tr key={index}>
                      <td scope="row">{index + 1}</td>
                      <td>{row.name}</td>
                      <td>{row.designation}</td>
                      <td>{row.qualification}</td>
                      <td>{row.technical_degree}</td>
                      <td>{row.experience}</td>
                      {/* <td>34 years</td> */}
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </section>
      {/* <!-- MEET THE MIND TEACHER --> */}

      <section className="wrapper" style={{ "background": "#00325a0a" }}>
        <div className="container">
          <div className="main-headwrap main-headwrapflex">
            <span>
              <h2 className="heading">Meet The Minds Shaping Our Teacher</h2>
            </span>
            {/* <a href="#" className="btn exploremoredes">Explore More</a> */}
          </div>
          <div className="row">
            {/* <!-- Team Member 1 --> */}
            {Teacher.map((item, index) => (
              <div className="col-md-3 team-member">
                {/* <> */}
                <img src={`${baseUrl}${item.Image}`} alt={item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)} className="img-fluid" />
                <div className="team-member-info">
                  <h5>{item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</h5>
                  <div className="social-icons">
                    <a style={{ color: 'black' }}>{item.designation && item.designation.charAt(0).toUpperCase() + item.designation.slice(1)}</a>
                    {/* <a href="#"><i className="fab fa-linkedin"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a> */}
                  </div>
                </div>
                {/* </> */}
              </div>
            ))}

          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default Teachers
