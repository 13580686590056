import React, { useEffect, useState } from 'react';
import { GETAPI } from '../Redux/Service';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Header = () => {
  const [Data, setData] = useState([]);
  const [Heading, setHeading] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [Alias, setAlias] = useState('');
  const getView = async () => {
    try {
      const result = await GETAPI("/list-menu");
      setData(result);
      const result3 = await GETAPI('/list-headline');
      setHeading(result3[0].description);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getView();
  }, []);

  useEffect(() => {
    if ($(window).width() < 991) {
      $('.login_active > a').on('click', function (e) {
        e.preventDefault();
        $(this).next('.login_pop').slideToggle();
      });
    }
  }, [Data]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <header>
        <div className="topbar--wrap">
          <marquee><a href="#">{Heading}</a></marquee>
          <ul className='topbar--flex-colnone'>
            <li><a href="/tc">TC</a></li>
            <li><a href="/teachers">Staff</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/infra-structure">Infrastructure</a></li>
            <li><a href="/books">Books</a></li>
          </ul>
        </div>
        <div className="headerwraap--top">
          <Link to='/'>
            <div className="logo">
              <img src="/assets/images/logo.svg" alt="Boson Logo" />
            </div>
          </Link>
          <div className="wrapheadertop-btn">
            <div className="wrap-topmail">
              <span><img src="../assets/images/icons/callheader.svg" alt="" /><a href="tel:7581082132">+91 7581-082132</a></span>
              <span><img src="../assets/images/icons/email.svg" alt="" /><a href='mailto:bosonschool@gmail.com'>bosonschool@gmail.com</a></span>
            </div>
            <a href="tel:7342524379" className="apply-btn"><i className="fas fa-phone"></i> <a href="tel:7342524379">+91 734-2524379</a></a>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            &#9776;
          </button>
        </div>
        <nav>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <li><Link to="/">Home</Link></li>
            {Data.map((item, index) => {
              let subMenus = [];
              try {
                subMenus = JSON.parse(item.SubMenuDetails);
              } catch (error) {
                console.error('Error parsing SubMenuDetails:', error);
              }

              return (
                <li key={index} className={item.IsSubMenu === "Yes" ? "login_active" : ""}>
                  {item.IsSubMenu === "Yes" ? (
                    <span style={{ color: 'white', fontSize: '16px' }} >{item.MenuName}</span>
                  ) : (
                    <Link to={`${item.MenuAlias}`}>{item.MenuName}</Link>
                  )}
                  {/* <Link to={`${item.MenuAlias}`}>{item.MenuName}</Link> */}
                  {item.IsSubMenu === "Yes" && subMenus.length > 0 && (
                    <div className="login_pop">
                      <div className="login_form">
                        <ul className="megamenu">
                          {subMenus.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Link to={`/details/${subItem.SMenuAlias}`}>{subItem.SMenuName}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
            <li className="login_active">
              <span style={{ color: 'white', fontSize: '16px' }}>Gallery</span>
              <div className="login_pop">
                <div className="login_form">
                  <ul className="megamenu">
                    <li><Link to="/photo-gallery">Image</Link></li>
                    <li><Link to="/video-gallery">Video</Link></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
