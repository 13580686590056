import React, { useEffect, useRef, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { GETAPI, MetabaseUrl } from '../Redux/Service';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
// ---------------------Developed by Disha Halkara-------------------------------
// ---------------------Date: 18-07-2024-----------------------------------------
const Home = () => {
    const [Data1, setData1] = useState([]);
    const [Slider, setSlider] = useState([]);
    const [Teacher, setTeacher] = useState([]);
    const carouselRef = useRef(null);
    const [youtubeVideos, setYouTubeVideos] = useState([]);
    const [simpleVideos, setSimpleVideos] = useState([]);
    // ---------------------Accessing the data through Api call-------------------------------

    const getView = async () => {
        try {
            const result = await GETAPI('/list-images');
            if (result) {
                setData1(result);

            }
            const result1 = await GETAPI('/list-slider');
            if (result1) {
                setSlider(result1);
                console.log('slider')

            }
            const result2 = await GETAPI('/list-teachers');
            if (result2) {
                setTeacher(result2);

            }
            const result3 = await GETAPI('/list-videos');
            if (result3 && result3) {
                // Separate data based on type
                const youtube = [];
                const simple = [];

                result3.forEach(item => {
                    const parsedVideos = JSON.parse(item.Videos);
                    if (item.type === 'youtubevideo') {
                        youtube.push({ Title: item.Title, Videos: parsedVideos });
                    } else if (item.type === 'simplevideo') {
                        simple.push({ Title: item.Title, Videos: parsedVideos });
                    }
                });

                setYouTubeVideos(youtube);
                setSimpleVideos(simple);
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        getView();
    }, []);
    // ---------------------Base URL for the Gallery-------------------------------

    const baseUrl = 'https://api.websitesolution.cloud'
    const Gallery = ({ images, title }) => {
        // Parse the images string into an array
        const parsedImages = JSON.parse(images);

        return (
            <div className="col-md-4 mb-4">
                <div className="gallery-item overlay">
                    <img src={`${baseUrl}${parsedImages[0]}`} alt={title} className="img-fluid" />
                    <div className="overlay-content">
                        <h3>{title}</h3>
                    </div>
                </div>
            </div>
        );
    };



    // Meta Tags 
    useEffect(() => {
        window.scrollTo(0, 0);

        // Update document title
        document.title = 'Boson International School | Excellence in Education | Ujjain';

        // Remove existing meta tags if any
        const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
        existingMetaTags.forEach(tag => tag.remove());

        // Create new meta tag for description
        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Welcome to Boson International School. Discover our mission, vision, and the excellent educational opportunities we offer. Join us to experience a world-class education.';
        document.head.appendChild(metaDescription);

        // Create meta tag for keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = 'Boson International School Ujjain, Best International School Ujjain, Quality Education Ujjain, International School Ujjain, Educational Excellence Ujjain, School Mission Ujjain, Leading School in Ujjain';

        document.head.appendChild(metaKeywords);

        // Create Open Graph meta tags for social sharing
        const ogTitle = document.createElement('meta');
        ogTitle.property = 'og:title';
        ogTitle.content = 'Boson International School | Excellence in Education';
        document.head.appendChild(ogTitle);

        const ogDescription = document.createElement('meta');
        ogDescription.property = 'og:description';
        ogDescription.content = 'Welcome to Boson International School. Discover our mission, vision, and the excellent educational opportunities we offer. Join us to experience a world-class education.';
        document.head.appendChild(ogDescription);

        const ogType = document.createElement('meta');
        ogType.property = 'og:type';
        ogType.content = 'website';
        document.head.appendChild(ogType);

        const ogUrl = document.createElement('meta');
        ogUrl.property = 'og:url';
        ogUrl.content = MetabaseUrl;
        document.head.appendChild(ogUrl);

        // Note: Replace with your actual image URL
        const ogImage = document.createElement('meta');
        ogImage.property = 'og:image';
        ogImage.content = `${MetabaseUrl}/assets/images/logo.svg`; // Replace with actual image URL
        document.head.appendChild(ogImage);

        const ogImageAlt = document.createElement('meta');
        ogImageAlt.property = 'og:image:alt';
        ogImageAlt.content = 'Boson International School';
        document.head.appendChild(ogImageAlt);

    }, []);


    const owlCrouselOptions = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: false,
        dots: false,
        smartSpeed: 1000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 1,
            },
        },
    };
    return (
        <>
            <Header />
            {/* <!-- HERO SECTION --> */}

            {/* <div id="owl-demo" className="owl-carousel owl-theme"> */}
            {/* {Slider.map((item, index) => ( */}
            <OwlCarousel
                ref={carouselRef}
                className="carousel-container owl-theme owl-carousel"
                options={owlCrouselOptions}
            >
                <div className="item">
                    <img src='../assets/images/slider.png' alt="Boson International School" />
                    {/* <img src={`${baseUrl}${item.FullImageUrl}`} alt="Boson International School" /> */}

                    <div className="carousel-caption">
                        <h6>Boson International School, Ujjain</h6>
                        <h1>
                            Excellence in <span>Education,</span> <span>Global Standards.</span>
                        </h1>
                        <p>
                            Welcome to Boson International School, where we are dedicated to fostering a vibrant and inclusive learning environment that prepares students for success in an ever-evolving global society. At Boson International, we prioritize academic excellence, holistic development, and the cultivation of critical thinking and creativity.
                        </p>
                        <a href="#" className="btn exploremoredes">Explore More</a>

                    </div>
                </div>
            </OwlCarousel>


            {/* </div> */}

            <div className="custom-nav">
                <button className="custom-prev">01</button>
                <button className="custom-next">02</button>
            </div>

            {/* <!-- WELCOME SECTION --> */}
            <section className="wrapper" >
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <div className="main-headwrap">
                                <h2 className="heading">Welcome to <span>BOSON</span> International!</h2>
                                <p className="headtext">
                                    The word, "BOSON. is derived from Satyendra Nath Bose, an Indian physicist from Kolkata who, in 1924 , realised that the statistical method used to analyse most 19th-century work on the thermal behaviour of gases was inadequate. He first sent off a paper on quantum statistics to a British journal , which turned it down. He then sent it to Albert Einstein , who immediately grasped its immense importance, and published it in a German journal. Bose's innovation carne to be known as the Bose-Einstein statistics, and became a basis of quantum mechanics. Einstein saw that it had profound implications for physics; that it had opened the way for this subatomic particle, which he named, after his Indian collaborator, "BOSON." To pay tribute to the work of Shri Satyendra Nath Bose we have named this institution . BOSON INTERNATIONAL SCHOOL

                                </p>
                            </div>
                            {/* <button className="btn">SEND A MESSAGE</button> */}
                        </div>
                        <div className="col-md-6">
                            <div className="welcomeinternationalimge">
                                <img src="assets/images/aboutimg.png" alt="Image 1" />
                                {/* <img src="assets/images/Diya-Decoration.jpg" alt="Image 1" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*  Comprehensive Child */}
            <section className="wrapper" style={{ "background": "#00325a0a" }}>

                <div className="row align-items-center mt-3">
                    <div className="col-md-6">
                        <img className="imagefulltalent" src="assets/images/childdevelopement.png" alt="Classroom Image" />
                    </div>
                    <div className="col-md-6">
                        <div className="main-wrapexplore">
                            <h4>
                                Child counseling  <strong>to Explore the hidden talents & potentials.</strong>
                            </h4>
                            <p>
                                Comprehensive child development services are essential for fostering the holistic growth of students, addressing their educational, health, nutritional, psychological, and extracurricular needs. At our school, we provide a robust educational program that spans early childhood, primary, and secondary education, ensuring a strong academic foundation through a diverse curriculum and innovative teaching methodologies. Extracurricular activities, including sports, arts, and various clubs, provide opportunities for students to explore their interests and develop new skills. We actively involve parents through workshops and the Parent-Teacher Association, fostering a supportive community environment. By integrating these comprehensive services, we aim to nurture well-rounded individuals prepared for future success.
                            </p>
                            {/* <a href="#" className="btn exploremoredes">Explore More</a> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="mainpaddcontact">
                <div className="row">
                    <div className="col-md-7 d-flex align-items-center p-0">
                        <div className="testimonial p-5">
                            <div className="playwraptext">
                                <p className="quote">What we Provide</p>
                                <p>We provide interactive learning with audio-visual aids, child counseling to uncover hidden talents, and physical development led by an M.Ped. qualified teacher. Our modernized computer lab, well-stocked library, and routine medical and dental check-ups ensure a comprehensive, pollution-free educational experience.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 p-0">
                        <div className="image-container">
                            <img src="assets/images/childdevelopement.png" className="img-fluid" alt="Testimonial Image" />
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- DIRECTOR SECTION --> */}
            <section className="wrapper" style={{ "background": "#00325a0a" }} >
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-5 marginright">
                            <figure className="imagesection--director"><img src="/assets/images/director.png" alt="" /></figure>
                        </div>
                        <div className="col-md-7 ">
                            <div className="main-headwrap">
                                <h2 className="heading">Director <span>institution dedicated </span></h2>
                                <p className="headtext">
                                    Under the visionary leadership of our Director, Boson International School in Ujjain is steadfastly committed to fostering an environment of academic excellence and holistic development. With a profound dedication to educational innovation and student success, our Director leads by example, ensuring that every aspect of our institution reflects a commitment to nurturing well-rounded individuals. By promoting a culture of continuous improvement and personalized learning experiences, our Director empowers both students and educators to strive for excellence in academics, character development, and community engagement. At Boson International School, our Director's unwavering dedication inspires a dynamic learning community where each student is encouraged to achieve their full potential and become future-ready global citizens.
                                </p>
                            </div>
                            <div className="principal--signa">
                                <h6>Director</h6>
                                <span>Dr. Gulrez Sheikh</span>
                                <b>Boson International School</b>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- Principal SECTION --> */}
            <section className="wrapper wrapperpri--section wrapperpri--dedicated"><div className="container"><div className="row align-items-center"><div className="col-md-12 marginright"><div className="main-headwrap"><h2 className="heading">Principal <span>institution dedicated </span></h2><p className="headtext">Boson International School strives to ignite curiosity, inspire creativity, and cultivate a lifelong love for learning. Through innovative teaching methodologies and state-of-the-art facilities, we aim to equip our students with the skills and knowledge necessary to thrive in a globalized world. At Boson International School, we uphold the values of integrity, compassion, and resilience, ensuring that each student achieves their fullest potential.</p>    <div className="principal--signa" style={{ color: 'white' }}>
                <h6>Principal</h6>
                <span>Seema Saxena</span>
                <b style={{ color: 'white' }}>Boson International School</b>
            </div></div></div></div></div></section>

            {/* <!-- GALLERY --> */}
            <section className="wrapper">
                <div className="container">
                    {/* Image */}
                    <div className="main-headwrap main-headwrapflex ">
                        <span>
                            <h2 className="heading">Exclusive Photo Gallery</h2>
                            <p>
                                A stunning aerial shot showcasing the sprawling campus of Boson
                                International School. The image captures the main academic buildings,
                                sports facilities, and lush green lawns.
                            </p>
                        </span>
                        <a href="/photo-gallery" className="btn exploremoredes">Explore More</a>
                    </div>

                    <div className="row">
                        {Data1.map((item, index) => (
                            <Gallery key={index} images={item.Images} title={item.Title} />
                        ))}
                    </div>
                    {/* Video */}
                    <div className="main-headwrap main-headwrapflex ">
                        <span>
                            <h2 className="heading">Exclusive Video Gallery</h2>
                            <p>
                                A stunning aerial shot showcasing the sprawling campus of Boson
                                International School. The image captures the main academic buildings,
                                sports facilities, and lush green lawns.
                            </p>
                        </span>
                        <a href="/video-gallery" className="btn exploremoredes">Explore More</a>
                    </div>
                    {youtubeVideos.map((data, index) => (
                        <>
                            <div className="row" key={index}>
                                <div className="main-headwrap main-headwrapflex">

                                    <h3>{data.Title}</h3>

                                </div>
                                {data.Videos.map((videoId, index) => (
                                    <div className="col-md-6" key={index}>

                                        <div className="details--page--imggrid">
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={`https://www.youtube.com/embed/${videoId}`}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>

                    ))}

                    {/* Render Simple Videos */}
                    {simpleVideos.map((data, index) => (
                        <>
                            <div className="row" key={index}>
                                <div className="main-headwrap main-headwrapflex">

                                    <h3>{data.Title}</h3>
                                </div>

                                {data.Videos.length > 0 ? (
                                    data.Videos.map((videoUrl, index) => (
                                        <div className="col-md-6" key={index}>
                                            <div className="details--page--imggrid">
                                                <video autoPlay loop controls width="100%" height="480">
                                                    <source type="video/mp4" src={`${baseUrl}${videoUrl}`} />
                                                </video>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No videos available</p>
                                )}


                            </div>
                        </>

                    ))}
                    {/* <div className="row"> */}

                    {/* {Data1.map((item, index) => (
                            <Gallery key={index} images={item.Images} title={item.Title} />
                        ))} */}
                    {/* </div> */}
                </div>
            </section>

            {/* <!-- MEET THE MIND TEACHER --> */}

            <section className="wrapper" style={{ "background": "#00325a0a" }}>
                <div className="container">
                    <div className="main-headwrap main-headwrapflex">
                        <span>
                            <h2 className="heading">Meet The Minds Shaping Our Teacher</h2>
                        </span>
                        <a href="/teachers" className="btn exploremoredes">Explore More</a>
                    </div>
                    <div className="row">
                        {/* <!-- Team Member 1 --> */}
                        {Teacher.map((item, index) => (
                            <div className="col-md-3 team-member" key={index}>

                                <img src={`${baseUrl}${item.Image}`} alt={item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)} className="img-fluid" />
                                <div className="team-member-info">
                                    <h5>{item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</h5>
                                    <div className="social-icons">
                                        <a style={{ color: 'black' }}>{item.designation && item.designation.charAt(0).toUpperCase() + item.designation.slice(1)}</a>

                                    </div>
                                </div>

                            </div>
                        ))}

                    </div>
                </div>
            </section>
            {/* <!-- INQUIRY --> */}
            <div className="mainpaddcontact">
                <div className="contact-section d-flex align-items-center">
                    <div className="container text-white">
                        <div className="row">
                            <div className="col-md-6 contactflexbox">
                                <h6>Contact Us</h6>
                                <h3>How To Enroll Your Child To a Class</h3>
                                <p>We'd love to hear from you! Whether you have questions, need more information, or want to schedule a
                                    visit, please feel free to reach out to us through any of the following methods:</p>
                                <Link to="/contact"><button className="btn ">Send a Message</button></Link>
                            </div>
                            <div className="wrapcontactemail">
                                <div className="contact-box contact-boxfrst">
                                    <img src="assets/images/icons/call.svg" alt="" />
                                    <h5>Contact With Email</h5>
                                    <p><a href='mailto:bosonschool@gmail.com' style={{ color: 'white' }}>Bosonschool@gmail.com</a></p>
                                </div>
                                <div className="contact-box">
                                    <img src="assets/images/icons/mail.svg" alt="" />
                                    <h5>Contact With Phone</h5>
                                    <p><a href="tel:7342524379" style={{ color: 'white' }}>+91 734-2524379</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default Home
