import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';

const Infra = () => {

   // Dynamic Meta Tags By Disha Halkara 29-7-24
   const [metaDescription, setMetaDescription] = useState('');
   const [metaKeywords, setMetaKeywords] = useState('');
   const [metaTitle, setMetaTitle] = useState('');
   const [ogImage, setOgImage] = useState('');
   const [ogUrl, setOgUrl] = useState('');
   const getMeta = async () => {
     try {
         const result = await MetaPOSTAPI('/list-meta-details', 'infra-structure');
      
         // Assume result contains the required meta data
         setMetaDescription(result[0].meta_description || 'Default description');
         setMetaKeywords(result[0].meta_keyword || 'Default keywords');
         setMetaTitle(result[0].meta_title || 'Default title');
         setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
         setOgUrl(result[0].og_url || MetabaseUrl);
     } catch (error) {
         console.error("Error fetching data:", error);
     }
 };
   // Update meta tags based on state
   useEffect(() => {
     window.scrollTo(0, 0);
 
     // Update document title
     document.title = metaTitle;
 
     // Remove existing meta tags if any
     const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
     existingMetaTags.forEach(tag => tag.remove());
 
     // Create and append meta tag for description
     const metaDescriptionTag = document.createElement('meta');
     metaDescriptionTag.name = 'description';
     metaDescriptionTag.content = metaDescription;
     document.head.appendChild(metaDescriptionTag);
 
     // Create and append meta tag for keywords
     const metaKeywordsTag = document.createElement('meta');
     metaKeywordsTag.name = 'keywords';
     metaKeywordsTag.content = metaKeywords;
     document.head.appendChild(metaKeywordsTag);
 
     // Create and append Open Graph meta tags
     const metaTags = [
         { property: 'og:title', content: metaTitle },
         { property: 'og:description', content: metaDescription },
         { property: 'og:type', content: 'website' },
         { property: 'og:url', content: ogUrl },
         { property: 'og:image', content: ogImage },
         { property: 'og:image:alt', content: metaTitle }
     ];
 
     metaTags.forEach(tag => {
         const metaTag = document.createElement('meta');
         metaTag.property = tag.property;
         metaTag.content = tag.content;
         document.head.appendChild(metaTag);
     });
 
 }, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);
 
   
   useEffect(() => {
 
     getMeta();
   }, []);
  const rooms = [
    { type: 'CLASS ROOMS', number: 18, size: '2.32 X 1.82 sq.mtr.' },
    { type: 'COMPOSITE SCIENCE LAB', number: 1, size: '2.31 X 1.81 sq.mtr.' },
    { type: 'PHYSICS LAB', number: 1, size: '2.52 X 1.76 sq.mtr.' },
    { type: 'CHEMISTRY LAB', number: 1, size: '2.78 X 1.76 sq.mtr.' },
    { type: 'BIOLOGY LAB', number: 1, size: '2.78 X 1.76 sq.mtr.' },
    { type: 'MATHS LAB', number: 1, size: '1.87 X 1.81 sq.mtr.' },
    { type: 'COMPUTER SCIENCE LAB', number: 1, size: '2.02 X 2.28 sq.mtr.' },
    { type: 'ACTIVITY LAB', number: 1, size: '2.32 X 1.82 sq.mtr.' },
    { type: 'LIBRARY', number: 1, size: '3.71 X 3.25 sq.mtr.' },
    { type: 'STAFF ROOM', number: 1, size: '2.32 X 1.82 sq.mtr.' },
    { type: 'OTHER ROOMS', number: 3, size: '2.30 X 1.84 sq.mtr.' }
  ];

  return (
    <>
      <Header />
      {/* <!-- Breadcamp SECTION --> */}
      <section className="breadcampsection">
        <div className="container">
          <div className="Breadcamp--text">
            {/* <h6>Infrastructure</h6> */}
            <h1>INFRASTRUCTURE</h1>
            <p>Details Of INFRASTRUCTURE / PlayGround</p>
          </div>
        </div>
      </section>
      {/* <!-- Table --> */}

      <section class="wrapper">
        <div class="container">
          <div class="row">

            <div class="tavle--mainwrap">
              <table class="table">
                <thead>
                  <tr>

                    <th scope="col">Sr.no </th>
                    <th scope="col">ROOMS</th>
                    <th scope="col">NUMBER</th>
                    <th scope="col">SIZE (Length & Breadth in sq.mtr.)</th>

                  </tr>
                </thead>
                <tbody>

                  {rooms.map((room, index) => (
                    <tr key={index}>
                      <td scope="row">{index + 1}</td>
                      <td>{room.type}</td>
                      <td>{room.number}</td>
                      <td>{room.size}</td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>

          </div>
          <h6>PLAY GROUND</h6>
          <p>1. Size of playground and details of games/sports/co-curricular activities for which facilities are available 120x11 sq.ft & 210x150 sq.ft(Lawn,Tennis,Badminton,Kho Kho ,Volley Ball,Children's playground)
          </p>

        </div>
      </section>
      <Footer />
    </>
  )
}

export default Infra
