import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { GETAPI, MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';

const Image = () => {
    const [Data1, setData1] = useState([]);

    // ---------------------Accessing the data through Api call-------------------------------

    const getView = async () => {
        try {
            const result = await GETAPI('/list-images');
            if (result) {
                setData1(result);

            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);

        getView();
    }, []);
    // ---------------------Base URL for the Gallery-------------------------------

    const baseUrl = 'https://api.websitesolution.cloud'
    const Gallery = ({ images, title }) => {
        // Parse the images string into an array
        const parsedImages = JSON.parse(images);

        return (
            <div className="col-md-4 mb-4">
                <div className="gallery-item overlay">
                    <img src={`${baseUrl}${parsedImages[0]}`} alt={title} className="img-fluid" />
                    <div className="overlay-content">
                        <h3>{title}</h3>
                    </div>
                </div>
            </div>
        );
    };
 // Dynamic Meta Tags By Disha Halkara 29-7-24
 const [metaDescription, setMetaDescription] = useState('');
 const [metaKeywords, setMetaKeywords] = useState('');
 const [metaTitle, setMetaTitle] = useState('');
 const [ogImage, setOgImage] = useState('');
 const [ogUrl, setOgUrl] = useState('');
 const getMeta = async () => {
   try {
       const result = await MetaPOSTAPI('/list-meta-details', 'photo-gallery');
    
       // Assume result contains the required meta data
       setMetaDescription(result[0].meta_description || 'Default description');
       setMetaKeywords(result[0].meta_keyword || 'Default keywords');
       setMetaTitle(result[0].meta_title || 'Default title');
       setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
       setOgUrl(result[0].og_url || MetabaseUrl);
   } catch (error) {
       console.error("Error fetching data:", error);
   }
};
 // Update meta tags based on state
 useEffect(() => {
   window.scrollTo(0, 0);

   // Update document title
   document.title = metaTitle;

   // Remove existing meta tags if any
   const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
   existingMetaTags.forEach(tag => tag.remove());

   // Create and append meta tag for description
   const metaDescriptionTag = document.createElement('meta');
   metaDescriptionTag.name = 'description';
   metaDescriptionTag.content = metaDescription;
   document.head.appendChild(metaDescriptionTag);

   // Create and append meta tag for keywords
   const metaKeywordsTag = document.createElement('meta');
   metaKeywordsTag.name = 'keywords';
   metaKeywordsTag.content = metaKeywords;
   document.head.appendChild(metaKeywordsTag);

   // Create and append Open Graph meta tags
   const metaTags = [
       { property: 'og:title', content: metaTitle },
       { property: 'og:description', content: metaDescription },
       { property: 'og:type', content: 'website' },
       { property: 'og:url', content: ogUrl },
       { property: 'og:image', content: ogImage },
       { property: 'og:image:alt', content: metaTitle }
   ];

   metaTags.forEach(tag => {
       const metaTag = document.createElement('meta');
       metaTag.property = tag.property;
       metaTag.content = tag.content;
       document.head.appendChild(metaTag);
   });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

 
 useEffect(() => {

   getMeta();
 }, []);


    return (
        <>
            <Header />
            {/* <!-- Breadcamp SECTION --> */}
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        {/* <h6>Contact us</h6> */}
                        <h1>Image Gallery</h1>
                        <p>A stunning aerial shot showcasing the sprawling campus of Boson International School. The image captures the main academic buildings, sports facilities, and lush green lawns.</p>
                    </div>
                </div>
            </section>
            <section class="wrapper" style={{ "background": "#00325a0a" }}>
                <div class="container">
                    <div className="row">
                        {Data1.map((item, index) => (
                            <Gallery key={index} images={item.Images} title={item.Title} />
                        ))}
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}

export default Image
