import React from 'react'
  // ---------------------Developed by Disha Halkara-------------------------------
  // ---------------------Date: 18-07-2024-----------------------------------------
const Footer = () => {
    return (
        <>
            {/* <!-- FOOTER --> */}
            <footer className="or_footer--wrapper">
                <div className="container">
                    <div className="or_footer--inner">
                        <div className="or_footer--left">
                            <div className="or_footer--content">
                                <div className="or_footer--logo">
                                    <a href="index.html"><img src="/assets/images/logo.svg" alt="Logo" className="img-fluid" /></a>
                                </div>
                                <p className="or_footer-desc">
                                Boson International School, is dedicated to fostering a dynamic learning environment where academic excellence meets holistic development. Established with a vision to nurture young minds and prepare them for a rapidly changing world, our school emphasizes a balanced approach to education.
                                {/* Dedicated to excellence in education, nurturing young minds, and fostering a lifelong love of learning. Our mission is to provide a supportive and inspiring environment where every child can discover and develop their unique talents. */}
                                </p>

                            </div>
                        </div>
                        <div className="or_footer--right">
                            <ul className="or_footer--social">
                                <li>
                                    <a href="javascript:void(0);"><img src="../assets/images/icons/facebook.svg" alt="Social" className="img-fluid" /></a>
                                </li>
                                {/* <li>
                                    <a href="javascript:void(0);"><img src="../assets/images/icons/linkd.svg" alt="Social" className="img-fluid" /></a>
                                </li> */}
                                <li>
                                    <a href="javascript:void(0);"><img src="../assets/images/icons/insta.svg" alt="Social" className="img-fluid" /></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"><img src="../assets/images/icons/youtoub.svg" alt="Social" className="img-fluid" /></a>
                                </li>
                            </ul>
                            <h3 className="or_footer--heading">Information</h3>
                            <ul className='or_footer--menu'>
            
            <li><a href="/teachers">Our Staff</a></li>
            <li><a href="/infra-structure">Infrastructure</a></li>
            <li><a href="/books">Books</a></li>
            <li><a href="/tc">Download TC</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
                            <h3 className="or_footer--heading">Contact Us</h3>
                            <div className="or_footer--location">
                                <div className="or_footer--locationBlock">
                                    <h4>Ujjain (MP):</h4>
                                    <p>
                                        <img src="../assets/images/icons/location.svg" alt="" />  Engineering collage road, Malanwasa, Ujjain, Madhya Pradesh 456010
                                    </p>
                                </div>
                                <div className="or_footer--locationBlock">
                                    <h4>Email:</h4>
                                    <p> <img src="../assets/images/icons/email.svg" alt="" /> <a href='mailto:bosonschool@gmail.com' style={{color:'white'}}> Bosonschool@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="or_copyright--wrapper">
                    <div className="container">
                        <div className="or_copyright--inner">
                            <p>© 2016-2024 Boson International School. All rights reserved.</p>

                            <p> Developed by  <a href="https://www.netspacesoftware.in" target="_blank" rel="Netspace Software Solutions"> Netspace Software Solutions Pvt Ltd. </a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
