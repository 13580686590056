import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { useParams } from 'react-router-dom';
import { MetabaseUrl, MetaPOSTAPI, POSTAPI } from '../Redux/Service';
// ---------------------Developed by Disha Halkara-------------------------------
// ---------------------Date: 18-07-2024-----------------------------------------
const Details = () => {
  const [heading, setHeading] = useState('');
  const [imageArray, setimageArray] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [video, setVideo] = useState([]);
  const [Document, setDocument] = useState([]);
  const [PDF, setPDF] = useState([]);
  const [shortdesc, setShortdesc] = useState('');
  const [longdesc, setlongdesc] = useState('');
  const { id } = useParams();

  // ---------------------Accessing the data through Api call-------------------------------
  const baseUrl = 'https://api.websitesolution.cloud'
  const getView = async () => {
    try {
      const result = await POSTAPI('/list-create-page', id);
     
      if (result) {

        setHeading(result[0].PageTitle);
        setShortdesc(result[0].PageShortDescription);

        if (result[0].PageLongDescription !== null) {
          setlongdesc(result[0].PageLongDescription);
          if ((result[0].YouTubeUpload === null)) {
            setYoutube([])
          }
          if ((result[0].ImageUpload === null)) {

            setimageArray([])
          }
          if ((result[0].DocUpload === null)) {

            setDocument([])
          }
          if ((result[0].PdfUpload === null)) {

            setPDF([])
          }
          if ((result[0].VideoUpload === null)) {

            setVideo([])
          }
        }
        if (result[0].PdfUpload !== null) {
          setPDF(JSON.parse(result[0].PdfUpload));
          if ((result[0].YouTubeUpload === null)) {
            setYoutube([])
          }
          if ((result[0].VideoUpload === null)) {

            setVideo([])
          }
          if ((result[0].ImageUpload === null)) {

            setimageArray([])
          }
          if ((result[0].DocUpload === null)) {

            setDocument([])
          }
          if ((result[0].PageLongDescription === null)) {

            setlongdesc('');

          }
        }

        if (result[0].ImageUpload !== null) {
          setimageArray(JSON.parse(result[0].ImageUpload));
          if ((result[0].YouTubeUpload === null)) {
            setYoutube([])
          }
          if ((result[0].VideoUpload === null)) {

            setVideo([])
          }
          if ((result[0].PageLongDescription === null)) {

            setlongdesc('');

          }
          if ((result[0].DocUpload === null)) {

            setDocument([])
          }
          if ((result[0].PdfUpload === null)) {

            setPDF([])
          }


        }
        if (result[0].DocUpload !== null) {
          setDocument(JSON.parse(result[0].DocUpload));
          if ((result[0].ImageUpload === null)) {

            setimageArray([])
          }
          if ((result[0].VideoUpload === null)) {

            setVideo([])
          }
          if ((result[0].YouTubeUpload === null)) {
            setYoutube([])
          }
          if ((result[0].PageLongDescription === null)) {

            setlongdesc('');

          }
          if ((result[0].PdfUpload === null)) {

            setPDF([])
          }
        }
        if (result[0].YouTubeUpload !== null) {
          setYoutube(result[0].YouTubeUpload);
          if ((result[0].ImageUpload === null)) {

            setimageArray([])
          }
          if ((result[0].VideoUpload === null)) {

            setVideo([])
          }
          if ((result[0].DocUpload === null)) {

            setDocument([])
          }
          if ((result[0].PageLongDescription === null)) {

            setlongdesc('');

          }
        }
        if (result[0].VideoUpload !== null) {
          setVideo(JSON.parse(result[0].VideoUpload));
          if ((result[0].YouTubeUpload === null)) {
            setYoutube([])
          }
          if ((result[0].ImageUpload === null)) {

            setimageArray([])
          }
          if ((result[0].DocUpload === null)) {

            setDocument([])
          }
          if ((result[0].PdfUpload === null)) {

            setPDF([])
          }
          if ((result[0].PageLongDescription === null)) {

            setlongdesc('');

          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const idsArray = typeof youtube === 'string' ? youtube.split(',').map(id => id.trim()) : [];

  // Dynamic Meta Tags By Disha Halkara 29-7-24
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [ogImage, setOgImage] = useState('');
  const [ogUrl, setOgUrl] = useState('');
  const getMeta = async () => {
    try {
        const result = await MetaPOSTAPI('/list-meta-details', id);
        // Assume result contains the required meta data
        setMetaDescription(result.meta_description || 'Default description');
        setMetaKeywords(result.meta_keyword || 'Default keywords');
        setMetaTitle(result.meta_title || 'Default title');
        setOgImage(result.og_img ||`${MetabaseUrl}assets/images/logo.svg`);
        setOgUrl(result.og_url || MetabaseUrl);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  // Update meta tags based on state
  useEffect(() => {
    window.scrollTo(0, 0);

    // Update document title
    document.title = metaTitle;

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create and append meta tag for description
    const metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.name = 'description';
    metaDescriptionTag.content = metaDescription;
    document.head.appendChild(metaDescriptionTag);

    // Create and append meta tag for keywords
    const metaKeywordsTag = document.createElement('meta');
    metaKeywordsTag.name = 'keywords';
    metaKeywordsTag.content = metaKeywords;
    document.head.appendChild(metaKeywordsTag);

    // Create and append Open Graph meta tags
    const metaTags = [
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: ogUrl },
        { property: 'og:image', content: ogImage },
        { property: 'og:image:alt', content: metaTitle }
    ];

    metaTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.property = tag.property;
        metaTag.content = tag.content;
        document.head.appendChild(metaTag);
    });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

  
  useEffect(() => {
    getView();
    getMeta();
  }, [id]);
  return (
    <>
      <Header />
      {/* <!-- Breadcamp SECTION --> */}
      <section className="breadcampsection">
        <div className="container">
          <div className="Breadcamp--text">
            {/* <h6>{heading}</h6> */}
            <h1>{heading}</h1>
            <p>{shortdesc}</p>
          </div>
        </div>
      </section>
      {/* <!-- Details  --> */}
      {longdesc ?
        <section className="wrapper" style={{ "background": "#00325a0a" }}>
          <div className="container">
            <div className="row align-items-center">

              <div className="main-headwrap">

                <p className="headtext" dangerouslySetInnerHTML={{ __html: longdesc }} />
              </div>

              <div className="col-md-6">

              </div>
            </div>
          </div>
        </section>
        : ""}
      {/* <!-- Image Grid --> */}
      {imageArray.length > 0 ?
        <section className="wrapper" >
          <div className="container">
            <div className="row">
              {imageArray.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="details--page--imggrid">
                    <figure>
                      <img src={`${baseUrl}${item}`} alt="" />
                    </figure>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        : " "}
      {/* <!-- Youtoub Grid --> */}
      {youtube.length > 0 ?
        <section className="wrapper" style={{ "background": "#00325a0a" }}>
          <div className="container">
            <div class="main-headwrap main-headwrapflex ">
              <span>
                <h2 class="heading">Video Gallery</h2>
              </span>
            </div>
            <div className="row">
              {idsArray.map((videoId, index) => (
                <div className="col-md-6" key={index}>
                  <div className="details--page--imggrid ">
                    <iframe width="100%" height="315"
                      src={`https://www.youtube.com/embed/${videoId}`}

                      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </div>
              ))}

            </div>

          </div>
        </section>

        : ""}
      {video.length > 0 ?
        <section className="wrapper" style={{ "background": "#00325a0a" }}>
          <div className="container">
            <div class="main-headwrap main-headwrapflex ">
              
            </div>
            <div className="row">

              <div className="col-md-6">
                <div className="details--page--imggrid ">
                  {video.map((item, index) => (
                    <video autoplay="" loop="" controls="" width="100%" height="480" key={index}>
                      <source type="video/mp4" src={`${baseUrl}${item}`}></source>
                    </video>
                  ))}

                </div>
              </div>


            </div>

          </div>
        </section>

        : ""}
      {/* <!-- PDF SECTION  -->  */}
      {PDF.length > 0 ?
        <section class="wrapper" style={{ "background": "#00325a0a" }}>
          <div class="container">
            <div class="main-headwrap main-headwrapflex ">
              {/* <h2 class="heading"><span>PDF </span></h2> */}
            </div>
            {PDF.map((item, index) => (
              <iframe key={index} src={`${baseUrl}${item}`} height="800" width="100%"></iframe>

            ))}
          </div>
        </section>
        : ""}
      <Footer />
    </>
  )
}

export default Details
