import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { useDispatch } from 'react-redux';
import { fetchPosts } from '../Redux/Contact';
import { toast } from 'react-toastify';
import { MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';
// ---------------------Developed by Disha Halkara-------------------------------
// ---------------------Date: 18-07-2024-----------------------------------------
const Contact = () => {
    const [CustomerName, setCustomerName] = useState();
    const [EmailAddress, setEmailAddress] = useState();
    const [Mobile, setMobile] = useState();
    const [Message, setMessage] = useState();
    const dispatch = useDispatch();
    const resetForm = () => {
        setCustomerName('');
        setEmailAddress('');
        setMobile('');
        setMessage('');
    };
    // --------------------Submit the values of Contact Form through FormData-------------------------------
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('Name', CustomerName);
        formData.append('Email', EmailAddress);
        formData.append('Mobile', Mobile);
        formData.append('Subject', Message);

        // ---------------------Api Call by the RTK Query Contact Slice in Redux folder-----------------------------------------

        const response = await dispatch(fetchPosts(formData));
        console.log(response.payload.success)

        if (response.payload.message === "Data Inserted Successfully and Email Sent.") {
            toast.success("Message Sent Successfully");
            resetForm();
        }
        else {
            toast.error(response.payload.message);
        }
    };

     // Dynamic Meta Tags By Disha Halkara 29-7-24
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [ogImage, setOgImage] = useState('');
  const [ogUrl, setOgUrl] = useState('');
  const getMeta = async () => {
    try {
        const result = await MetaPOSTAPI('/list-meta-details', 'contact');
     
        // Assume result contains the required meta data
        setMetaDescription(result[0].meta_description || 'Default description');
        setMetaKeywords(result[0].meta_keyword || 'Default keywords');
        setMetaTitle(result[0].meta_title || 'Default title');
        setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
        setOgUrl(result[0].og_url || MetabaseUrl);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  // Update meta tags based on state
  useEffect(() => {
    window.scrollTo(0, 0);

    // Update document title
    document.title = metaTitle;

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create and append meta tag for description
    const metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.name = 'description';
    metaDescriptionTag.content = metaDescription;
    document.head.appendChild(metaDescriptionTag);

    // Create and append meta tag for keywords
    const metaKeywordsTag = document.createElement('meta');
    metaKeywordsTag.name = 'keywords';
    metaKeywordsTag.content = metaKeywords;
    document.head.appendChild(metaKeywordsTag);

    // Create and append Open Graph meta tags
    const metaTags = [
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: ogUrl },
        { property: 'og:image', content: ogImage },
        { property: 'og:image:alt', content: metaTitle }
    ];

    metaTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.property = tag.property;
        metaTag.content = tag.content;
        document.head.appendChild(metaTag);
    });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

  
  useEffect(() => {

    getMeta();
  }, []);
    return (
        <>
            <Header />
            {/* <!-- Breadcamp SECTION --> */}
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        <h6>Contact us</h6>
                        <h1>Get in Touch</h1>
                        <p>Reach out to Boson International School for inquiries, admissions, or to schedule a visit. We're here to assist you in every step of your educational journey.</p>
                    </div>
                </div>
            </section>

            <div className="wrapcontactframe">
                <div className="iframelocation">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14674.860590249538!2d75.802639!3d23.144085!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963749c20338995%3A0x3e7bf76a0627b1b3!2sBoson%20international%20school!5e0!3m2!1sen!2sin!4v1721993027018!5m2!1sen!2sin" width="100%" height="600px" style={{ "border": "0" }} title='Boson International School' allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="contactform">
                    <div className="form-section">
                        {/* Form for the Contact Page */}
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="firstName">Enter your Name</label>
                                    <input type="text" className="form-control" id="firstName" 
                                    value={CustomerName} placeholder="Enter your Name" required onChange={(event) => {
                                        setCustomerName(event.target.value);
                                    }} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group">
                                    <label for="firstName">Enter your Email</label>
                                    <input type="email" className="form-control" id="firstName" 
value={EmailAddress}
                                    placeholder="Enter your Email" required onChange={(event) => {
                                        setEmailAddress(event.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label for="firstName">Enter your Mobile no.</label>
                                    <input
                                    value={Mobile}
                                     type="text" minLength={10} maxLength={10} className="form-control" id="firstName" placeholder="Enter your Mobile" required onChange={(event) => {
                                        setMobile(event.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="description">Enter your Description</label>
                                <textarea className="form-control" id="description" rows="3" 
                                value={Message}
                                placeholder="Enter your Message" required onChange={(event) => {
                                    setMessage(event.target.value);
                                }}></textarea>
                            </div>
                            <button type='submit' className="btn">SEND A MESSAGE</button>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact
