import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Pages/Home";
import Contact from "./Components/Pages/Contact";
import TC from "./Components/Pages/TC";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Infra from "./Components/Pages/Infra";
import Books from './Components/Pages/Books';
import Teachers from './Components/About/Teachers';
import Details from "./Components/Details/Details";
import Image from "./Components/Pages/Image";
import Video from "./Components/Pages/Video";

function App() {
  return (
    <>
      <Routes>
        {/* Menu Pages / Single Pages */}
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/books" element={<Books />}></Route>
        <Route path="/photo-gallery" element={<Image />}></Route>
        <Route path="/video-gallery" element={<Video />}></Route>
        <Route path="/tc" element={<TC />}></Route>
        <Route path="/details/:id" element={<Details />}></Route>
        <Route path="/teachers" element={<Teachers />}></Route>
      <Route path='/infra-structure' element={<Infra/>}></Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
